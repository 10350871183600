import React from 'react'
import styled, { css } from 'styled-components'
import { Flip } from 'react-reveal'
import codeImg from '../../assets/imgs/code.jpg'

import {
  Container,
  Link as A,
  Section,
  Box,
  Text,
  theme
} from '@hackclub/design-system'
import { Headline, Lead } from 'components/Content'
import Link from 'components/Link'

const Blog = props => {
  A.link = A.withComponent(Link)
  const FeatureLink = styled(A.link).attrs({
    mt: 3,
    fontSize: 3,
    hoverline: true,
    chevronRight: true
  })`
    display: block;
  `
  const SectionLead = styled(Lead).attrs({
    fontSize: [3, 4],
    maxWidth: 88,
    mx: 0,
    pb: 3,
    mb: [4, 5]
  })``

  const shadows = css`
    h1,
    h2,
    h3,
    p,
    li,
    ${FeatureLink} {
      color: ${theme.colors.white};
      text-shadow: 0 1px 4px rgba(0, 0, 0, 1);
    }
  `
  const Slider = styled(Section).attrs({
    color: 'white'
  })`
    position: relative;
    border-radius: ${theme.radii[2]};
    background-color: ${theme.colors.primary};
    ${props =>
      props.inverted
        ? css`
            justify-content: flex-end !important;
            background-image: linear-gradient(
                'rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.4)'
              ),
              url(${props.src});
          `
        : css`
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.3),
                rgba(0, 0, 0, 0.2) 50%
              ),
              url(${props.src});
          `};
    background-position: center;
    background-size: cover;
    background-repeat: norepeat;
    ${shadows};
  `
  return (
    <>
      <div key={props.blog.id}>
        <Slider
          style={{ paddingTop: 50 }}
          src={props.blog.picture ? props.blog.picture.file.url : codeImg}
          py={[3, 5, 6]}
          color="white"
          align={['left', 'center']}
        >
          <Container width={1} maxWidth={70} px={3} mt={[5, 6]} mb={[4, 5]}>
            <Flip top>
              <Headline maxWidth={58} mx="auto" fontSize={[6, 7, 8]} mb={2}>
                {props.blog.title}
              </Headline>
            </Flip>
          </Container>
        </Slider>
        <Box bg="white" py={[5, 6]}>
          <Container color="black" px={3}>
            <Box px={[1, 8]}>
              <SectionLead>{props.blog.body.body}</SectionLead>
            </Box>
            <Box px={[1, 8]}>
              <Text fontSize={2} color="blue.6">
                Author
              </Text>
              <SectionLead>{props.blog.author[0].name}</SectionLead>
            </Box>
          </Container>
        </Box>
      </div>
    </>
  )
}

export default Blog
