import React from 'react'
import Layout from 'components/Layout'
import Nav from 'components/Nav'
import Footer from 'components/Footer'
import { graphql } from 'gatsby'
import Social from '../components/Socialicons'
import Blog from '../components/Blog/Blog'

export default ({ data }) => {
  let blog = data.allContentfulPost.nodes[0]
  return (
    <Layout
      title={`${blog.title} at- Coding Sastra`}
      desc={blog.body.body}
      keywords={blog.body.body}
      path="/post/"
    >
      <Nav />
      <Social />
      <Blog blog={blog} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query BlogPageQuery($id: String) {
    allContentfulPost(filter: { id: { eq: $id } }) {
      nodes {
        id
        title
        picture {
          file {
            url
          }
        }
        author {
          name
        }
        body {
          body
        }
      }
    }
  }
`
